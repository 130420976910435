@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');

.picContainer {
    position: relative !important;
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden !important;
}

.faenaMainPic {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
}

/* Media queries para móviles */
@media (max-width: 600px) {
    .picContainer {
        height: 26vh !important;
    }
}