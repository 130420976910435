@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');

.picContainer {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
}

.faenaMainSPic {
    width: 76.2% !important;
    height: auto !important;
    max-height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
}