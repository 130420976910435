@import url('https://fonts.googleapis.com/css2?family=Golos+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,800;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url(https://db.onlinewebfonts.com/c/d6ae884b0a2f1a5346717fc160db2d28?family=Roobert);


h1 {
    color: #ffffff;
    font-family: "Roobert";

}

h1:hover {
    cursor: pointer;
}
.sectiontTitleContainer {
    position: absolute;
    margin-top: 130px;
    margin-left: 100px;
    

}
.sectiontTitleFaena{
    color: white!important;
    font-size: 60px!important;
    letter-spacing: 1px!important;
    font-weight: 300!important;
}
.sectionSubTitle{
    display: flex;
}
.faenaRow{
    display: flex;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    gap: 205px;
    padding-top: 90px;
}
.faenaRow2{
    display: flex;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    gap: 240px;
    padding-top: 90px;
}
.faenaH5{
    color: white!important;
    letter-spacing: 2px;
}
.sloganContainerFaena {
    width: 100%;
        min-height: 130vh;
        display: flex;
        position: relative;
        justify-content: left;
        align-items: center;
        transition: 1.1s;
}
.sloganContainerFaena .homeLogo2 {
    max-width: 68%;
    position: absolute;
    bottom: 20vh;
    right: 0;
    pointer-events: none;
    transition-duration: 2s;
}

.slogan {
    position: absolute;
    margin-bottom: 200px;
    font-size: 20px;
    font-weight: 300;
    color: rgb(160, 160, 160);
    font-family: 'Kanit', sans-serif;
}

.slogaSubtitle {
    font-size: 120px;
    font-weight: 200;
    margin-bottom: 90px;
    font-family: 'Kanit', sans-serif;
}

.slogaSubtitle2 {
    position: absolute;
    font-size: 120px;
    font-weight: 200;
    margin-bottom: 50px;
    font-family: 'Kanit', sans-serif;
    color: #8a8a8a;
    opacity: 3%;
    transition: 1s;
}

@media only screen and (max-width: 600px) {
    .slogaSubtitle {
        font-size: 50px;
        line-height: 30px;
    }

    .slogaSubtitle2 {
        font-size: 50px;
        line-height: 30px;
    }

    .slogan {
        font-size: 15px;
        margin-bottom: 160px;
    }

    .sloganContainer .homeLogo {
        max-width: 85%;
    }

}
/* Consulta de medios para pantallas más pequeñas */
@media only screen and (max-width: 600px) {

    .generalContainer .faenaRow {
        gap: 62px;
    }


    .generalContainer .row2 {
        padding-bottom: 40px;
        padding-right: 30px;
    }

    .generalContainer p {
        width: auto;
    }

    .generalContainer .row4 {
        padding-top: 40px;
    }
}
/* Estilos base */
body {
    margin: 0;
    padding: 0;
    font-family: 'Kanit', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

/* Media queries para móviles */
@media (max-width: 1540px) {
    .sloganContainerFaena .homeLogo2 {
        max-width: 60%;
    }
}
/* Media queries para móviles */
@media (max-width: 600px) {
    .sectiontTitleContainer {
        margin-top: 5vw;
        margin-left: 5vw;
    }

    .sectiontTitle {
        font-size:30px;
        /* Tamaño más grande para pantallas pequeñas */
    }

    .faenaRow,
    .faenaRow2 {
        gap: 25vw;
        padding-top: 40px;
    }
    .sectiontTitleFaena{
        font-size: 24px!important;
    }
    .sloganContainerFaena .homeLogo2 {
        max-width: 100%;
        bottom: 5vh;
    }
    .sloganContainerFaena{
        min-height: 75vh;
    }
}

