@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,800;0,900;1,100&display=swap');

body,
html {
  margin: 0;
  padding: 0;
}
.pepe{
  height: 1px;
}
section {
  padding: 80px 0;
  pointer-events: none
  
}
.generalContainer {
  max-width: 100%;
  padding: 0 80px;
  margin: auto;
  pointer-events: none;
  
}
.titleContainer {
  position: relative;
  text-align: center;
}

.titleContainer .p1 {
  text-align: left;
  margin-left: 28px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 700;
  color: white;
}
.p1Left {
  font-family: 'Kanit', sans-serif;
  padding-right: 2px;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 100;
  color: rgb(160, 160, 160);
}
.descriptionContainer {
  display: flex;
}

.descriptionContainer p {
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  text-align: LEFT;
  font-weight: 200;
}
@media screen and (max-width:850px) {
  .descriptionContainer p {
    font-size: 24px;
  }
}
.generalTextContainer {
  max-width: 1300px;
  padding: 0 80px;
  margin: auto;
  pointer-events: none;
  
}
.App {
  max-width: 100%;
  padding: 1;
  z-index: 1;
}
canvas{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -1;
  min-width: 100%; 
  min-height: 100%;
  width: auto; 
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 690px) {
  .generalTextContainer {
    padding: 0 24px;
  }
  .generalContainer {
    padding: 0 20px;
  } 
}