@import url('https://fonts.googleapis.com/css2?family=Golos+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,800;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


h1 {
    color: #ffffff;
    font-family: 'Golos Text', sans-serif;
}

h1:hover {
    cursor:pointer;
}

.sloganContainer {
    width: 100%;
    min-height: 93vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1.1s;
}

.slogan {
    position: absolute;
    margin-bottom: 200px;
    font-size: 20px;
    font-weight: 300;
    color: rgb(160, 160, 160);
    font-family: 'Kanit', sans-serif;
}

.slogaSubtitle {
    font-size: 120px;
    font-weight: 200;
    margin-bottom: 90px;
    font-family: 'Kanit', sans-serif;
}
.slogaSubtitle2 {
    position: absolute;
    font-size: 120px;
    font-weight: 200;
    margin-bottom: 50px;
    font-family: 'Kanit', sans-serif;
    color: #8a8a8a;
    opacity: 3%;
    transition: 1s;
}

.sloganContainer .homeLogo{
    max-width: 500px;
    padding-bottom: 120px;
}

@media only screen and (max-width: 600px) {
    .slogaSubtitle {
        font-size: 50px;
        line-height: 30px;
    }
    .slogaSubtitle2 {
        font-size: 50px;
        line-height: 30px;
    }
    .slogan {
        font-size: 15px;
        margin-bottom: 160px;
    }
    .sloganContainer .homeLogo{
        max-width: 85%;
    }

}
/* Consulta de medios para pantallas más pequeñas */
@media only screen and (max-width: 600px) {
    .generalContainer h2 {
        font-size: 30px;
        line-height: 30px;
        width: auto;
    }

    .generalContainer hr {
        width: 300px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .generalContainer .row {
        gap: 80px;
    }

    .generalContainer .row2 {
        padding-bottom: 40px;
        padding-right: 30px;
    }

    .generalContainer p {
        width: auto;
    }

    .sectionFooter {
        padding-top: 0;
    }

    .lowFooter .inc {
        font-size: 11px;
    }

    .row p {
        font-size: 14px;
    }

    .generalContainer .row4 {
        padding-top: 40px;
    }
}