@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');

.titleContainer {
    position: relative;
    text-align: center;
}

.titleContainer .p1 {
    font-size: 14px;
    font-weight: 400;
    color: #C7C7C780;
}
.blur{
    backdrop-filter: blur(10px);
        background-color: rgba(53, 53, 53, 0.034);
}
/* Media queries para móviles */
@media (max-width: 600px) {
    .descriptionContainer p {
        font-size: 16px!important;
    }
    .generalTextContainer{
        padding: 0 20px!important;
    }
    .lastText{
        font-size: 24px!important;
    }
}