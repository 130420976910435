@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');
@import url(https://db.onlinewebfonts.com/c/d6ae884b0a2f1a5346717fc160db2d28?family=Roobert);
.generalFaenaDescContainer {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
    pointer-events: none;
}

.titleContainer {
    position: relative;
    text-align: center;
}

.titleContainer .p1 {
    font-size: 14px;
    font-weight: 400;
    color: #C7C7C780;
}

.titleContainer .p2 {
    font-size: 100px;
    font-weight: 400;
    margin-bottom: 300px;
    position: absolute;
    height: fit-content;
    right: 0;
    top: 0;
    padding: 320px 0px 0px 400px;
}
.descriptionContainerFaena {
    display: flex;
    gap: 50px;
}

.descriptionContainerFaena p {
    padding-right: 150px;
    font-size: 30px;
    font-family: "Roobert";
    font-style: normal;
    text-align: LEFT;
    font-weight: 500;
}
/* Media queries para móviles */
@media (max-width: 600px) {
    .descriptionContainerFaena {
            flex-direction: row;
            /* Cambiar a fila en pantallas más grandes */
        }
    .descriptionContainerFaena p {
            /* Permitir que los párrafos se expandan para ocupar el espacio disponible */
            padding-right: 1px;
            /* Reducción del padding entre párrafos en pantallas más grandes */
            font-size: 20px;
            margin-bottom: 20px;
        }
    .descriptionContainerFaena {
            display: inline;
            gap: 5px;
        }
    .faenadescripcion{
        padding-top: 40px!important;
        padding-bottom: 20px!important;
    }
    .generalFaenaDescContainer {
            max-width: 1300px;
            padding: 0 20px;
            margin: auto;
            pointer-events: none;
    }
}