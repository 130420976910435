@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');
@import url(https://db.onlinewebfonts.com/c/d6ae884b0a2f1a5346717fc160db2d28?family=Roobert);

.generalFaenaParagraphContainer {
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
    pointer-events: none;
}

.titleContainer {
    position: relative;
    text-align: center;
}

.titleContainer .p1 {
    font-size: 14px;
    font-weight: 400;
    color: #C7C7C780;
}

.titleContainer .p2 {
    font-size: 100px;
    font-weight: 400;
    margin-bottom: 300px;
    position: absolute;
    height: fit-content;
    right: 0;
    top: 0;
    padding: 320px 0px 0px 400px;
}

.descriptionContainerFaenaParagraph {
    display: flex;
    margin-top: 5px;
    gap: 50px;
}

.descriptionContainerFaenaParagraph p {
    padding-right: 120px;
    font-size: 16px;
    line-height: 1.4;
    font-family: "Roobert";
    font-style: normal;
    text-align: LEFT;
    font-weight: 500;
}

/* Media queries para móviles */
@media (max-width: 600px) {
    .generalFaenaParagraphContainer {
        padding: 0 20px;
    }

    .generalFaenaParagraphContainer p {
        padding-right: 1px;
    }

    .descriptionContainerFaenaParagraph {
        display: inline;
    }
}